import mapboxgl from "!mapbox-gl";

function getMapboxBounds(waypoints) {
    if(waypoints.length <= 1) {
        return null;
    }

    let mostNorth = waypoints[0].latitude;
    let mostSouth = waypoints[0].latitude;
    let mostWest = waypoints[0].longitude;
    let mostEast = waypoints[0].longitude;

    for(const waypoint of waypoints) {
        if(waypoint.latitude > mostNorth) {
            mostNorth = waypoint.latitude;
        }
        if(waypoint.latitude < mostSouth) {
            mostSouth = waypoint.latitude;
        }
        if(waypoint.longitude < mostWest) {
            mostWest = waypoint.longitude;
        }
        if(waypoint.longitude > mostEast) {
            mostEast = waypoint.longitude;
        }
    }

    const southWest = new mapboxgl.LngLat(mostWest, mostSouth);
    const northEast = new mapboxgl.LngLat(mostEast, mostNorth);
    return new mapboxgl.LngLatBounds(southWest, northEast);
}

export default getMapboxBounds;
