import React from "react";
import axios from "axios";
import {
    withRouter
} from "react-router-dom";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import {
    withOrganisationsContext
} from "../../../context/OrganisationsContext";
import Helmet from "../../../components/Helmet";
import RideForm from "./detail/components/RideForm";
import getEventErrorMessage from "../events/getEventErrorMessage";
import rideDateInputToAPI from "./rideDateInputToAPI";
import Loading from "../../../components/Loading";

class RideAdd extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            event: null,
            error: null,

            errorSave: null,
            loading: false,

            name: "",
            startDate: "",
            endDate: "",
            autoStart: false,
            autoStop: false,
        };
        this.onChangeRide = this.onChangeRide.bind(this);
        this.addRide = this.addRide.bind(this);
    }

    componentDidMount() {
        if(this.props.organisationsContext.currentOrganisation) {
            this.getEvent();
        }
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if(this.props.organisationsContext.currentOrganisation !== prevProps.organisationsContext.currentOrganisation) {
            this.getEvent();
        }
    }

    getEvent() {
        this.setState({ event: null });
        axios.post("/getEvent", {
            eventId: this.props.match.params.eventId,
            organisationId: this.props.organisationsContext.currentOrganisation.id
        })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ event: response.data.event });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    addRide() {
        this.setState({ loading: true, errorSave: null });
        const {
            name,
            startDate,
            endDate,
            autoStart,
            autoStop,
        } = this.state;
        const currentOrganisation = this.props.organisationsContext.currentOrganisation;
        axios.post("/addRide", {
            organisationId: currentOrganisation.id,
            eventId: this.state.event.id,
            name,
            startDate: rideDateInputToAPI(startDate),
            endDate: rideDateInputToAPI(endDate),
            autoStart: autoStart ? 1 : 0,
            autoStop: autoStop ? 1 : 0,
        })
            .then((response) => {
                if(response.data.valid) {
                    const ride = response.data.ride;
                    const baseUrl = "/organisation/" + currentOrganisation.codeName;
                    const rideUrl = baseUrl + "/events/" + ride.eventId + "/rides/" + ride.id;
                    this.props.history.push(rideUrl);
                } else {
                    this.setState({ loading: false, errorSave: getEventErrorMessage(response.data.error) });
                    window.scroll({ top: 0, behavior: "smooth" });
                }
            })
            .catch((error) => {
                console.error(error);
                window.scroll({ top: 0, behavior: "smooth" });
                this.setState({ loading: false, errorSave: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    onChangeRide(changes) {
        this.setState({ ...changes });
    }

    render() {
        const {
            event,
            error,
            loading,
            errorSave
        } = this.state;
        if(error) {
            return (
                <Alert variant="danger">
                    { error }
                </Alert>
            );
        }
        if(!event) {
            return (
                <Loading/>
            );
        }
        return (
            <React.Fragment>
                <Helmet title="Nieuwe rit aanmaken"/>
                <Title preTitle="Overzicht">
                    Nieuwe rit aanmaken
                </Title>
                { errorSave && (
                    <Alert variant="danger">
                        { errorSave }
                    </Alert>
                )}
                <div className="card mb-3">
                    <div className="card-body">
                        <h4 className="card-title">Eventement</h4>
                        <p className="card-text">
                            { event.name }
                        </p>
                    </div>
                </div>
                <RideForm
                    ride={ this.state }
                    setRide={ this.onChangeRide }
                    loading={ loading }
                />
                <Button
                    variant="primary"
                    className="float-right"
                    disabled={ loading }
                    onClick={ this.addRide }
                >
                    { loading && (
                        <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                    )}
                    Opslaan
                </Button>
            </React.Fragment>
        );
    }
}

export default withOrganisationsContext(withRouter(RideAdd));
